import { isPostalCodeCombinationWithoutNumber } from '../../../templates/vue-elements/comparison-lib/util';
import { loadOrderFlow } from './orderFlow';

// Add this helper function at the top of the file
function logEvent(eventName, params = {}) {
    if (window.bananas) {
        window.bananas('event', eventName, params);
    }
}

export function setFieldError(postalCheck, field, message) {
    const errorFields = postalCheck.querySelectorAll(`[data-error-field='${field}']`);
    errorFields.forEach((errorField) => {
        errorField.innerHTML = message;
        errorField.classList.add('visible');
        errorField.closest('div.field')
            .classList
            .add('error');
    });
}

export function clearFieldError(postalCheck, field) {
    const errorFields = postalCheck.querySelectorAll(`[data-error-field='${field}']`);
    errorFields.forEach((errorField) => {
        errorField.innerHTML = '';
        errorField.classList.remove('visible');
        errorField.closest('div.field')
            .classList
            .remove('error');
    });
}

export function checkForm(postalCheck, showErrors = true) {
    const postalCodeInput = postalCheck.getElementsByClassName('input-postcode')[0];
    const numberInput = postalCheck.getElementsByClassName('input-nr')[0];
    const numberExtensionInput = postalCheck.getElementsByClassName('input-extension')[0];
    const form = postalCheck.getElementsByTagName('form')[0];
    const industry = postalCheck.attributes.getNamedItem('data-industry').value;
    const currentProviderInput = postalCheck.getElementsByClassName('input-currentprovider')[0];
    const shortInput = postalCheck.getElementsByClassName('input-short')[0];

    // Uppercase all the fields!
    postalCodeInput.value = postalCodeInput.value.trim()
        .toUpperCase();
    numberInput.value = numberInput.value.trim()
        .toUpperCase();
    numberExtensionInput.value = numberExtensionInput.value.trim()
        .toUpperCase();
    clearFieldError(postalCheck, 'postcode');
    clearFieldError(postalCheck, 'number-extension');

    if (!showErrors) {
        return;
    }

    if (postalCodeInput.value === '' && numberInput.value === '' && numberExtensionInput.value === '') {
        // Skip all checks, continue to submitting
    } else {
        // Check input
        if (postalCodeInput.value === '') {
            setFieldError(postalCheck, 'postcode', 'Je hebt geen postcode ingevuld');
            logEvent(`compary-${industry}.postalbox-error`, { field: 'postcode' });
            return;
        }

        if (!isPostalCodeCombinationWithoutNumber(postalCodeInput.value)) {
            setFieldError(postalCheck, 'postcode', 'Vul een geldige postcode in. Bijvoorbeeld 1234AB');
            logEvent(`compary-${industry}.postalbox-error`, { field: 'postcode' });
            return;
        }

        if (numberInput.value === '') {
            setFieldError(postalCheck, 'number-extension', 'Vul een huisnummer in. Bijvoorbeeld: 1');
            logEvent(`compary-${industry}.postalbox-error`, { field: 'number' });
            return;
        }

        const validNumberStartRegex = /^[0-9]/;
        if (!validNumberStartRegex.test(numberInput.value)) {
            setFieldError(postalCheck, 'number-extension', 'Vul een huisnummer in. Bijvoorbeeld: 1');
            logEvent(`compary-${industry}.postalbox-error`, { field: 'number' });
            return;
        }

        // Why was this here?
        // if (numberExtensionInput.value.trim().length > 0) {
        // numberInput.value = `${numberInput.value} ${numberExtensionInput.value}`;
        // numberExtensionInput.value = null;
        // }
    }

    // Log event
    let wizardVersion = 'legacy';
    if (typeof shortInput !== 'undefined') {
        const storedVersion = window.localStorage.getItem('wizardVersion');
        if (storedVersion === 'medium' || storedVersion === 'short') {
            wizardVersion = storedVersion;
        }
        logEvent(`compary-${industry}.wizardVersion-${wizardVersion}`);
    } else {
        logEvent(`compary-${industry}.wizardVersion-legacy`);
    }

    logEvent(`compary-${industry}.postalbox-submit`, { wizardVersion });

    postalCheck.classList.add('loading');

    // Check if orderflow should be loaded
    if (postalCheck.dataset.orderFlow && postalCheck.dataset.orderFlowData) {
        try {
            const orderFlowData = JSON.parse(postalCheck.dataset.orderFlowData);

            // If there is no provider slug, just submit the form
            if (!orderFlowData.providerSlug) {
                form.submit();
                return;
            }
            const postalData = {
                postalCode: postalCodeInput.value,
                number: numberInput.value,
                extension: numberExtensionInput.value,
                currentProvider: currentProviderInput?.value,
            };
            loadOrderFlow(false, postalData, orderFlowData)
                .then(() => {
                    postalCheck.classList.remove('loading');
                });
        } catch (error) {
            form.submit();
        }
    } else {
        form.submit();
    }
}

export function zipCodeInputType(postalCodeInput) {
    if (postalCodeInput.value.length > 3) {
        return 'text';
    }
    return 'numeric';
}

function getAddressFromLocalStorage() {
    if (window.localStorage.getItem('compareLocation')) {
        return JSON.parse(window.localStorage.getItem('compareLocation')).data;
    }
    return null;
}

export function setAddressInLocalStorage(postCode, number, numberExtension, currentProvider = null) {
    if (postCode && number) {
        localStorage.setItem('compareLocation', JSON.stringify({
            data: {
                postCode,
                number,
                numberExtension,
                currentProvider,
            },
        }));
    }
}

export function setCurrentProviderInLocalStorage(currentProvider) {
    const location = getAddressFromLocalStorage();

    if (currentProvider === null || location === null) {
        return;
    }

    location.currentProvider = currentProvider;

    setAddressInLocalStorage(location.postCode, location.number, location.numberExtension, location.currentProvider);
}

function checkCurrentProvider(currentProviderInput) {
    if (currentProviderInput.value !== '' && currentProviderInput.value !== null) {
        currentProviderInput.classList.add('has-value');
    } else {
        currentProviderInput.classList.remove('has-value');
    }
}

export function registerPostalCheck(postalCheckElement) {
    const postalCodeInput = postalCheckElement.getElementsByClassName('input-postcode')[0];
    const numberInput = postalCheckElement.getElementsByClassName('input-nr')[0];
    const numberExtensionInput = postalCheckElement.getElementsByClassName('input-extension')[0];
    const currentProviderInput = postalCheckElement.getElementsByClassName('input-currentprovider')[0];
    const form = postalCheckElement.getElementsByTagName('form')[0];

    const location = getAddressFromLocalStorage();
    if (location) {
        postalCodeInput.value = location.postCode ? location.postCode : '';
        numberInput.value = location.number ? location.number : '';
        numberExtensionInput.value = location.numberExtension ? location.numberExtension : '';
        if (currentProviderInput) {
            currentProviderInput.value = location.currentProvider ? location.currentProvider : '';
        }
    }

    const body = document.getElementsByTagName('body')[0];
    body.onclick = () => {
        checkForm(postalCheckElement, false);
    };

    postalCheckElement.classList.remove('loading');
    if (currentProviderInput) {
        checkCurrentProvider(currentProviderInput);
        currentProviderInput.onchange = () => {
            checkCurrentProvider(currentProviderInput);
        };
    }

    // const industry = postalCheckElement.attributes.getNamedItem('data-industry').value;

    postalCodeInput.onchange = () => {
        postalCodeInput.value = postalCodeInput.value.trim();
    };

    postalCodeInput.oninput = () => {
        postalCodeInput.setAttribute('inputmode', zipCodeInputType(postalCodeInput));
    };

    numberInput.onchange = () => {
        numberInput.value = numberInput.value.trim();
    };

    numberExtensionInput.onchange = () => {
        numberExtensionInput.value = numberExtensionInput.value.trim();
    };

    form.onsubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        checkForm(postalCheckElement, true);
    };

    postalCodeInput.onkeydown = () => {
        checkForm(postalCheckElement, false);
    };

    numberInput.onkeydown = () => {
        checkForm(postalCheckElement, false);
    };
}
